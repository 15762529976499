export const menuItems = [

    {
        id: 1,
        label: "menuitems.edit_blog.text",
        icon: 'ri-pencil-ruler-2-line',
        link: '/admin/edit_blog'
    },
    {
        id: 2,
        label: "menuitems.blog.text",
        icon: 'ri-dashboard-line',
        link: '/admin/blog'
    },

    {
        id: 3,
        label: "menuitems.catlog.text",
        icon: 'ri-stack-line',
        link: '/admin/catlog'
    },
    {
        id: 4,
        label: "menuitems.tag.text",
        icon: 'ri-apps-2-line',
        link: '/admin/tags'
    },
    {
        id: 5,
        label: "menuitems.user.text",
        icon: 'ri-map-pin-line',
        link: '/admin/user'
    },

]
